<template>
  <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ formTitle() }}</h4>
        </template>
        <b-container fluid>
          <b-row>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-overlay :show="loading">
                  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
                    <b-row>
                      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
                            <b-form-group
                              label-for="garden_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                :disabled="isGardenAdmin"
                                plain
                                v-model="beneficiary.garden_id"
                                :options="masterTeaGardenGenInfoList"
                                id="garden_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                            <b-form-group
                              label-for="service_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('teaGardenConfig.service_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="beneficiary.service_id"
                                :options="serviceList"
                                id="service_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="Planting Material" vid="planting_material_id">
                            <b-form-group
                              label-for="planting_material_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('teaGardenBtriService.planting_material') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="details.planting_material_id"
                                :options="plantingMaterialList"
                                id="planting_material_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                                {{ planting_material_error !== '' ? planting_material_error : errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="clone_name_id" vid="clone_name_id">
                            <b-form-group
                              label-for="clone_name_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('teaGardenBtriService.clone_variety') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="details.clone_name_id"
                                :options="masterCloneNameList"
                                id="clone_name_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback d-block">
                                {{ clone_name_error !== '' ? clone_name_error : errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="Beneficiary Count" vid="clone_price" v-slot="{ errors }">
                          <b-form-group
                              label-for="clone_price">
                              <template v-slot:label>
                                {{ $t('globalTrans.price') }}
                              </template>
                              <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="beficiary_count"
                              v-model="details.clone_price"
                              :state="errors[0] ? false : (valid ? true : null)"
                              readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2" v-if="user_id === 1">
                        <ValidationProvider name="Beneficiary Count" vid="stock" v-slot="{ errors }">
                          <b-form-group
                              label-for="stock">
                              <template v-slot:label>
                                {{ $t('globalTrans.stock') }}
                              </template>
                              <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="beficiary_count"
                              v-model="details.stock"
                              :state="errors[0] ? false : (valid ? true : null)"
                              readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="Beneficiary Count" vid="total_qty" v-slot="{ errors }">
                          <b-form-group
                              label-for="total_qty">
                              <template v-slot:label>
                                {{ $t('globalTrans.quantity') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="beficiary_count"
                              v-model="details.total_qty"
                              @input="payableAmountCalculate()"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback d-block">
                                {{ qty_error }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <ValidationProvider name="Beneficiary Count" vid="total_price" v-slot="{ errors }">
                          <b-form-group
                              label-for="total_price">
                              <template v-slot:label>
                                {{ $t('globalTrans.tot_price') }}
                              </template>
                              <b-form-input
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              id="beficiary_count"
                              v-model="details.total_price"
                              :state="errors[0] ? false : (valid ? true : null)"
                              readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row class="text-right">
                      <b-col>
                          <b-button @click="addItem()" type="button" variant="success" class="mr-2 mb-2 btn-sm" :disabled="buttonDisable">
                              {{ $t('globalTrans.add') }}
                          </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                        <!-- -----------------Add More Start------------------- -->
                        <div class="table-wrapper table-responsive">
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenBtriService.planting_material') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenBtriService.clone_variety') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.price') }}</b-th>
                                <b-th class="text-center" v-if="user_id === 1 && editID">{{ $t('globalTrans.stock') }}</b-th>
                                <b-th class="text-center" width="10%">{{ $t('globalTrans.quantity') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.tot_price') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.action') }} </b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="beneficiary.details && beneficiary.details.length">
                                  <b-tr v-for="(item, index) in beneficiary.details" :key="index">
                                      <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                      <b-td class="text-center">{{ getMatName(item.planting_material_id) }}</b-td>
                                      <b-td class="text-center">{{ getCloneName(item.clone_name_id) }}</b-td>
                                      <b-td class="text-center">{{ $n(item.price) }}</b-td>
                                      {{ id }}
                                      <b-td class="text-center" v-if="user_id === 1 && editID"> {{ $n(item.clone_quantity.stock_quantity) }}</b-td>
                                      <b-td class="text-center"> {{ $n(item.qty) }}</b-td>
                                      <b-td class="text-center">{{ $n(item.total_price) }}</b-td>
                                      <td class="text-center">
                                        <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="removeItem(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                      </td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td class="text-right" colspan="5" v-if="user_id === 1 && editID > 0">{{ $t('globalTrans.grand_total') }}</b-td>
                                    <b-td class="text-right" colspan="4" v-if="user_id === 1 && editID === 0">{{ $t('globalTrans.grand_total') }}</b-td>
                                    <b-td class="text-right" colspan="4" v-if="user_id !== 1">{{ $t('globalTrans.grand_total') }}</b-td>
                                    <b-td class="text-center" >{{ $n(getTotalQty()) }}</b-td>
                                    <b-td class="text-center">{{ $n(beneficiary.total_price) }}</b-td>
                                  </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                        <!-- -----------------Add More End--------------------- -->
                    </b-row>
                    <b-row class="text-right mb-3">
                        <b-col>
                          <b-button type="submit" @click="beneficiary.status = 2" variant="warning" class="mr-2" :disabled="buttonDisable">{{$t('configuration.save_draft')}}</b-button>
                          <b-button type="submit" @click="beneficiary.status = 1" variant="primary" class="mr-2" :disabled="buttonDisable">{{$t('configuration.final_save')}}</b-button>
                          <router-link :to="{ path: '/tea-garden-service/btri/service-app-list'}" class="btn btn-danger btn-sm">
                              {{ $t('globalTrans.cancel') }}
                          </router-link>
                        </b-col>
                    </b-row>
                  </b-form>
                </b-overlay>
              </ValidationObserver>
          </b-row>
        </b-container>
      </card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { btriServiceAppStore, btriServiceUpdate, btriServiceEdit, getClonePrice } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
data () {
  return {
    plantingMaterialList: [],
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      beneficiary: {
        insert_by: 1,
        status: 0,
        user_id: 0,
        applicant_name_en: '',
        applicant_name_bn: '',
        garden_id: 0,
        service_id: 0,
        total_qty: 0,
        total_price: 0,
        details: []
      },
      details: {
        garden_id: 0,
        clone_price: 0,
        service_id: 0,
        planting_material_id: 0,
        clone_name_id: 0
      },
      is_disable: false,
      isGardenAdmin: false,
      planting_material_error: '',
      clone_name_error: '',
      qty_error: '',
      editID: 0,
      buttonDisable: false
  }
},
created () {
  this.user_id = this.$store.state.Auth.authUser.user_id
  if (this.$route.query.id) {
    this.editID = this.$route.query.id
    this.getFormData()
  }

  if (this.isGardenAdminCheckGardenId()) {
    this.isGardenAdmin = true
    this.beneficiary.garden_id = this.isGardenAdminCheckGardenId()
    this.beneficiary.insert_by = 2
  }
},
computed: {
  serviceList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => [20, 21].includes(parseInt(item.value)))
  },
  valleyList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterValleyList
  },
  madeTeaGradeList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterMadeTeaGradeList
  },
  masterCloneNameList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterCloneNameList
  },
  masterTeaGardenGenInfoList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
  }
},
watch: {
  'beneficiary.service_id': function (newVal, oldVal) {
    if (newVal !== oldVal) {
      this.plantingMaterialList = this.getPantMaterials(newVal)
    } else {
      this.plantingMaterialList = []
    }
  },
  'details.planting_material_id': function (newVal, oldVal) {
    if (newVal !== oldVal) {
      this.planting_material_error = ''
    }
  },
  'details.clone_name_id': function (newVal, oldVal) {
    if (newVal !== oldVal) {
      this.clone_name_error = ''
      this.getClonePrice()
    }
  },
  'beneficiary.garden_id': function (newVal, oldVal) {
    this.getOfficeUser(newVal)
  }
},
methods: {
  async getFormData () {
    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.getData(teaGardenServiceBaseUrl, `${btriServiceEdit}/${this.$route.query.id}`)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.beneficiary = result.data
    } else {
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: 'Clone Price not Found!!!',
        color: '#D6E09B'
      })
    }
    this.loading = false
  },
  async getOfficeUser (id) {
    this.loading = true
    const gardenData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
    this.office_id = gardenData.office_id
    const params = {
      office_id: this.office_id
    }
    const result = await RestApi.getData(authServiceBaseUrl, '/user-management/user/office-user', params)
    this.loading = false
    if (result.success) {
      if (result.data) {
        this.buttonDisable = false
        this.beneficiary.user_id = result.data.value
        this.beneficiary.applicant_name_en = result.data.text_en
        this.beneficiary.applicant_name_bn = result.data.text_bn
      } else {
        this.buttonDisable = true
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Garden User not Found!!!'
        })
      }
    }
  },
  async addItem () {
    if (this.details.planting_material_id && this.details.clone_name_id && this.details.total_qty) {
      this.planting_material_error = ''
      this.clone_name_error = ''
      this.qty_error = ''
      var obj = {
        planting_material_id: this.details.planting_material_id,
        clone_name_id: this.details.clone_name_id,
        price: this.details.clone_price,
        qty: this.details.total_qty,
        total_price: parseInt(this.details.total_price)
      }
      const extItem = this.beneficiary.details.find(eItm => eItm.planting_material_id === this.details.planting_material_id && eItm.clone_name_id === this.details.clone_name_id)
      if (extItem === undefined) {
        this.is_disable = true
        this.beneficiary.details.push(obj)
        this.beneficiary.total_qty += parseInt(this.details.total_qty)
        this.beneficiary.total_price += parseInt(this.details.total_price)
      }
      this.details = {
        planting_material_id: 0,
        clone_name_id: 0,
        price: '',
        qty: '',
        total_price: ''
      }
      this.$refs.form.reset()
    } else {
      if (this.details.planting_material_id === 0) {
        this.planting_material_error = 'Planting Material is not valid .'
      }
      if (this.details.clone_name_id === 0) {
        this.clone_name_error = 'Clone/Variety Name is not valid .'
      }
      if (!this.details.total_qty) {
        this.qty_error = 'Quantity is not valid .'
      }
    }
  },
  removeItem (index) {
    this.beneficiary.total_qty = this.beneficiary.total_qty - this.beneficiary.details[index].qty
    this.beneficiary.total_price = this.beneficiary.total_price - this.beneficiary.details[index].total_price
    this.beneficiary.details.splice(index, 1)
  },
  async saveUpdate () {
    if (!this.beneficiary.details.length) {
      return this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: 'Please add at least one clone'
      })
    }
    if (this.user_id === 1 && this.editID > 0) {
      let stockCheck = 1
      this.beneficiary.details.forEach(item => {
        if (item.qty > item.clone_quantity.stock_quantity) {
          stockCheck = 2
        }
      })
      if (stockCheck === 2) {
        return this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: 'Stock Limited!!!'
        })
      }
    }

    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    if (this.$route.query.id) {
      result = await RestApi.putData(teaGardenServiceBaseUrl, `${btriServiceUpdate}/${this.$route.query.id}`, this.beneficiary)
    } else {
      result = await RestApi.postData(teaGardenServiceBaseUrl, btriServiceAppStore, this.beneficiary)
    }
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
        color: '#D6E09B'
      })
      this.$router.push({ path: '/tea-garden-service/btri/service-app-list' })
    } else {
      this.$refs.form.setErrors(result.errors)
    }
    this.loading = false
  },
  async getClonePrice () {
    if (this.details.clone_name_id > 0) {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.getData(teaGardenServiceBaseUrl, getClonePrice, this.details)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.details.stock = result.stockQty ? result.stockQty.stock_quantity : 0
        this.details.clone_price = result.data.price
      } else {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: 'Clone Price not Found!!!',
          color: '#D6E09B'
        })
      }
      this.loading = false
    }
  },
  getPantMaterials (id) {
    return this.$store.state.TeaGardenService.commonObj.plantingMaterialList.filter(item => item.status === 1 && item.service_id === id)
  },
  payableAmountCalculate () {
    this.qty_error = ''
    if (this.user_id === 1) {
      if ((parseInt(this.details.total_qty) > this.details.stock)) {
        const actualQty = this.details.stock
        this.$nextTick(() => {
          this.details.total_qty = parseInt(actualQty)
        })
      }
    }

    this.details.total_price = 0
    const clonePrice = this.details.clone_price
    this.details.total_price = parseInt(clonePrice) * parseInt(this.details.total_qty)
  },
  getMatName (id) {
    const data = this.plantingMaterialList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
  },
  getCloneName (id) {
    const data = this.masterCloneNameList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
  },
  formTitle () {
      return (!this.$route.query.id) ? this.$t('teaGardenBtriService.tea_plant_app') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenBtriService.tea_plant_app') + ' ' + this.$t('globalTrans.modify')
  },
  getTotalQty () {
      let totalQty = 0
      this.beneficiary.details.forEach(item => {
        totalQty += parseFloat(item.qty)
      })
      return totalQty
  },
  approvalCalculation (index) {
    const obj = this.beneficiary.details[index]

    if ((parseInt(obj.qty) > obj.clone_quantity.stock_quantity)) {
      const actualQty = obj.clone_quantity.stock_quantity
      this.$nextTick(() => {
        obj.qty = parseInt(actualQty)
        obj.total_price = parseInt(obj.price) * obj.qty
      })
    } else {
      obj.total_price = parseInt(obj.price) * parseInt(obj.qty)
    }
  }
  }
}
</script>
